// import

import type {ButtonProps, LinkButtonProps} from './Styles/Button';
import type {ComponentProps, ElementType, ReactNode} from 'react';

import * as Alert from '@radix-ui/react-alert-dialog';
import {useNavigate} from '@remix-run/react';
import {forwardRef} from 'react';

import {cram, isBool} from '@cat/rambo';

import {Button as BaseButton, LinkButton as BaseLinkButton} from './Styles/Button';
import {IconClose} from './Styles/Icons';
import {Link as BaseLink} from './Styles/Link';

// types

type ContentProps = ComponentProps<typeof Alert.Content> & {
  title: ReactNode;
  actions?: ReactNode;
  cancelable?: ReactNode;
  titleAs?: ElementType;
};

type RootAsRouteProps = ComponentProps<typeof Alert.Root> & {
  exitTo?: string;
  hideOverlay?: boolean;
};

// styles

const overlayCls = cram(
  'fixed inset-0 z-50',
  'dots bg-orange-50/50 backdrop-blur-[3px]',
);

const placeCls = cram(
  'fixed z-50 top-0 left-0 bottom-0 right-0',
  'flex items-center justify-center px-1r4 py-3r',
);
const contentCls = cram(
  'w-full max-w-b6 max-h-full bg-orange-25',
  'flex flex-col overflow-hidden',
  'rounded-md border-2 border-pinkZ-800',
  'shadow-hard-4 shadow-pinkZ-800',
);

const headerCls = cram(
  'border-b-2 border-pinkZ-800 bg-blue-300',
  'px-1r4 py-1r flex gap-r4',
);
const descriptionCls = cram(
  'px-1r4 pt-1r6 pb-2r flex-shrink overflow-y-auto',
);
const footerCls = cram(
  'border-t-2 border-pinkZ-800 bg-orange-100',
  'px-1r4 py-1r2 flex gap-r6',
);

const titleCls = cram('font-head text-1r4 font-semibold flex-grow');

// components

export function Root(props: ComponentProps<typeof Alert.Root>) {
  const {children, ...rest} = props;

  return (
    <Alert.Root {...rest}>
      <Alert.Portal>
        <Alert.Overlay className={overlayCls} />

        {children}
      </Alert.Portal>
    </Alert.Root>
  );
}

export function RootAsRoute(props: RootAsRouteProps) {
  const {exitTo, children, hideOverlay, ...rest} = props;
  const navigate = useNavigate();

  const onOpenChange = (open: boolean) => {
    if (open) {
      return;
    }

    if (exitTo) {
      return navigate(exitTo);
    }

    window.history.back();
  };

  return (
    <Alert.Root {...rest} open onOpenChange={onOpenChange}>
      {hideOverlay ? null : (
        <Alert.Overlay className={overlayCls} />
      )}

      {children}
    </Alert.Root>
  );
}

export function RawContent(props: ComponentProps<'div'>) {
  const {children, className, ...rest} = props;

  return (
    <div {...rest} className={cram(placeCls, className)}>
      {children}
    </div>
  );
}

export const Content =
forwardRef<HTMLDivElement, ContentProps>((props, ref) => {
  const {children, title, actions, cancelable, className, titleAs: Title = 'h2', ...rest} = props;

  return (
    <div className={placeCls}>
      <Alert.Content
        {...rest}
        ref={ref}
        className={cram(contentCls, className)}
      >
        <header className={headerCls}>
          <Alert.Title asChild className={titleCls}>
            <Title>{title}</Title>
          </Alert.Title>

          {cancelable ? (
            <Alert.Cancel>
              <IconClose />
            </Alert.Cancel>
          ) : null}
        </header>

        <Alert.Description asChild className={descriptionCls}>
          <div>
            {children}
          </div>
        </Alert.Description>

        {actions || cancelable ? (
          <footer className={footerCls}>
            {actions}

            {cancelable ? (
              <CancelButton color="gray">
                {isBool(cancelable) ? 'Cancel' : cancelable}
              </CancelButton>
            ) : null}
          </footer>
        ) : null}
      </Alert.Content>
    </div>
  );
});

export const Trigger =
forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
  return (
    <Alert.Trigger asChild>
      <BaseButton {...props} ref={ref} />
    </Alert.Trigger>
  );
});

export const Button =
forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
  return (
    <Alert.Action asChild>
      <BaseButton {...props} ref={ref} />
    </Alert.Action>
  );
});

export const LinkButton =
forwardRef<HTMLAnchorElement, LinkButtonProps>((props, ref) => {
  return (
    <Alert.Action asChild>
      <BaseLinkButton {...props} ref={ref} />
    </Alert.Action>
  );
});

export const Link =
forwardRef<HTMLAnchorElement, LinkButtonProps>((props, ref) => {
  return (
    <Alert.Action asChild>
      <BaseLink {...props} ref={ref} />
    </Alert.Action>
  );
});

export const CancelButton =
forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
  return (
    <Alert.Cancel asChild>
      <BaseButton {...props} ref={ref} />
    </Alert.Cancel>
  );
});
